.progress-bar-container {
    width: 100%;
    background-color: #e0e0df;
    border-radius: 10px;
    overflow: hidden;
    position: relative;
    left: -40%;
  }
  
  .progress-bar {
    transition: width 0.3s ease;
    border-radius: 10px 0 0 10px;
  }
  
  .progress-bar-label {
    position: absolute;
    top: 50%;
    left: 40%;
    transform: translate(-50%, -50%);
    font-size: 14px;
    color: #fff;
    font-weight: bold;
  }
  